import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

const changeMeetingDate = (changeDate ) => {
    console.log("service working");
    const data = {
        changeMeetingDateToken: changeDate,
    };
    console.log('data', data);
    const url = `${PAISANOW_API_URL}UpdateMeetingDate`;
    return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export default changeMeetingDate;


