import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './login/login.reducer';
import departmentReducer from './department/department.reducer';
import deliveryProcessReducer from './deliveryProcess/deliveryProcess.reducer';
import companyReducer from './company/company.reducer';
import staffReducer from './staff/staff.reducer';
import branchReducer from './branch/branch.reducer';
import compareReducer from './compare/compare.reducer';
import loanIndexReducer from './loanIndex/loanIndex.reducer';
import memberReducer from './member/member.reducer';
import profileReducer from './profile/profile.reducer';
import ndcReducer from './memberNDC/memberNDC.reducer';
import bankReducer from './bank/bank.reducer';
import cbCheckReducer from './cbCheck/cbCheck.reducer';
import questionnaireReducer from './questionnaire/questionnaire.reducer';
import blockChainReducer from './blockChain/blockChain.reducer';
import dashboardReducer from './dashboard/dashboard.reducer';
import productReducer from './product/product.reducer';
import disbursementReducer from './disbursement/disbursement.reducer';
import demandReportReducer from './demand/demandReport.reducer';
import repaymentReportReducer from './repayment/repaymentReport.reducer';
import userTypeReducer from './userType/userType.reducer';
import targetReducer from './target/target.reducer';
import changeMeetingDateReducer from './changeMeetingDate/changeDate.reducer';
// import delinQuencyReportReducer from './delinQuencyReport/delinQuencyReport.reducers';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['login'],
};
const rootReducer = combineReducers({
	login: userReducer,
	department: departmentReducer,
	deliveryList: deliveryProcessReducer,
	company: companyReducer,
	staff: staffReducer,
	userType: userTypeReducer,
	branch: branchReducer,
	compare: compareReducer,
	loanIndex: loanIndexReducer,
	member: memberReducer,
	profile: profileReducer,
	ndc: ndcReducer,
	bank: bankReducer,
	cbCheck: cbCheckReducer,
	questionnaire: questionnaireReducer,
	blockChain: blockChainReducer,
	dashboard: dashboardReducer,
	product: productReducer,
	disbursement: disbursementReducer,
	demandReport: demandReportReducer,
	repaymentReport: repaymentReportReducer,
	target: targetReducer,
	changeMeetingDate: changeMeetingDateReducer,
	// delinQuencyReport:delinQuencyReportReducer,
});

export default persistReducer(persistConfig, rootReducer);
