/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { call, all, put, takeLatest } from 'redux-saga/effects';
import changeMeetingDateTypes from './changeDate.types';
import generalTypesAction from '../genera.types';
import { encodeJson } from '../enode-decode';
import changeMeetingDate from './changeDate.services';
import {
    changeMeetingDateSuccess,
    changeMeetingDateFailure,
    changeMeetingDateChange,

} from './changeDate.actions';

// change meeting date start

export function* changeMeetingDateStart({ payload }) {
    try {
        const jsonData = {
            loanRequestID: payload.loanRequestID,
            isLinkedCompanyID: payload.isLinkedCompanyID,
            collectionType: payload.collectionType,
            memberID: payload.memberID,
            memberName:payload.memberName,
            userID:payload.userID,
            userName: payload.userName,
            currentDate: payload.currentDate,
            changeDate: payload.changeDate,
            selectedCollectionDay: payload.selectedCollectionDay,
            selectedDateType: payload.selectedDateType,
        };
        const changeMeetingDateData = encodeJson(jsonData, generalTypesAction.CHANGEDATE);
        const responseData = yield call(changeMeetingDate,changeMeetingDateData);
        if (responseData.data.statuCode === '100') {
            yield put(changeMeetingDateFailure(responseData.data));
        } else {
            yield put(changeMeetingDateSuccess(responseData.data.response));
        }
    } catch (error) {
        yield put(changeMeetingDateFailure(error));
    }
};

export function* changeMeetingDateChanged() {
    yield put(changeMeetingDateChange());
};
// change meeting date end


export function* changeMeetingDateStartSaga() {
    yield takeLatest(changeMeetingDateTypes.CHANGE_MEETING_DATE_START, changeMeetingDateStart)
};

export function* changeMeetingDateChangeSaga() {
    yield takeLatest(changeMeetingDateTypes.CHANGE_MEETING_DATE_RESET, changeMeetingDateChanged)
}


export function* changeMeetingDateSaga() {
    yield all([
        call(changeMeetingDateStartSaga),
        call(changeMeetingDateChangeSaga),
    ])
}