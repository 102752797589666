import changeMeetingDateTypes from "./changeDate.types";
import generalTypesAction from "../genera.types";
import { getEndodeJsonData } from "../common/common.utils";



const INITIAL_STATE = {
    changeMeetinDateData: null,
    changeMeetingDateStatus: null,
};


// eslint-disable-next-line default-param-last
const changeMeetingDateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case changeMeetingDateTypes.CHANGE_MEETING_DATE_SUCCESS:
            return {
                ...state,
                 changeMeetinDateData: getEndodeJsonData(action.payload,generalTypesAction.CHANGEDATE),
                 changeMeetingDateStatus: 'Success',
            };
        case changeMeetingDateTypes.CHANGE_MEETING_DATE_FAILURE:
            return {
                ...state,
                changeMeetinDateData: getEndodeJsonData(action.payload,generalTypesAction.CHANGEDATE),
                changeMeetingDateStatus: 'Failure',
            };
        case changeMeetingDateTypes.CHANGE_MEETING_DATE_CHANGED:
            return {
                ...state,
                changeMeetingDateStatus: null,
            };

        default:
            return state;
    }
};
export default changeMeetingDateReducer;