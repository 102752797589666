const memberActionTypes = {
	MEMBER_GET_ALL_LIST_START: 'member_get_all_list_start',
	MEMBER_GET_ALL_SUCCESS: 'member_get_all_success',
	MEMBER_GET_ALL_FAILURE: 'member_get_all_failure',
	MEMBER_GET_ALL_CHANGED: 'member_get_all_changed',
	MEMBER_GET_ALL_RESET: 'member_get_all_reset',

	MEMBER_GET_DETAILS_START: 'member_get_details_start',
	MEMBER_GET_DETAILS_SUCCESS: 'member_get_details_success',
	MEMBER_GET_DETAILS_FAILURE: 'member_get_details_failure',
	MEMBER_GET_DETAILS_CHANGED: 'member_get_details_changed',
	MEMBER_GET_DETAILS_RESET: 'member_get_details_reset',

	MEMBER_BANK_FETCH_START: 'member_bank_fetch_start',
	MEMBER_BANK_FETCH_SUCCESS: 'member_bank_fetch_success',
	MEMBER_BANK_FETCH_FAILURE: 'member_bank_fetch_failure',
	MEMBER_BANK_FETCH_CHANGED: 'member_bank_fetch_changed',
	MEMBER_BANK_FETCH_RESET: 'member_bank_fetch_reset',

	MEMBER_CONTACT_FETCH_START: 'member_contact_fetch_start',
	MEMBER_CONTACT_FETCH_SUCCESS: 'member_contact_fetch_success',
	MEMBER_CONTACT_FETCH_FAILURE: 'member_contact_fetch_failure',
	MEMBER_CONTACT_FETCH_CHANGED: 'member_contact_fetch_changed',
	MEMBER_CONTACT_FETCH_RESET: 'member_contact_fetch_reset',

	MEMBER_GET_LOAN_DETAILS_START: 'member_get_loan_details_start',
	MEMBER_GET_LOAN_DETAILS_SUCCESS: 'member_get_loan_details_success',
	MEMBER_GET_LOAN_DETAILS_FAILURE: 'member_get_loan_details_failure',
	MEMBER_GET_LOAN_DETAILS_CHANGED: 'member_get_loan_details_changed',
	MEMBER_GET_LOAN_DETAILS_RESET: 'member_get_loan_details_reset',

	MEMBER_GET_FILTER_SEARCH_START: 'member_get_filter_search_start',

	MEMBER_REJECT_START: 'MEMBER_REJECT_START',
	MEMBER_REJECT_SUCCESS: 'MEMBER_REJECT_SUCCESS',
	MEMBER_REJECT_FAILURE: 'MEMBER_REJECT_FAILURE',
	MEMBER_REJECT_CHANGED: 'MEMBER_REJECT_CHANGED',
	MEMBER_REJECT_RESET: 'MEMBER_REJECT_RESET',

	SEARCH_MEMBER_START:'search_member_start',
    SEARCH_MEMBER_SUCCESS:'search_member_success',
    SEARCH_MEMBER_FAILURE: 'search_member_failure',
    SEARCH_MEMBER_CHANGED: 'search_member_change',
    SEARCH_MEMBER_RESET:'search_member_reset',
};
export default memberActionTypes;
