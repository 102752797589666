/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { PAISANOW_API_URL } from '../const';

export const getBorrowersList = (memberData) => {
	const data = {
		BorrowerMemberListToken: memberData,
	};
	const url = `${PAISANOW_API_URL}BorrowerMemberFetchList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getBorrowersDetails = (memberData) => {
	const data = {
		BorrowerMemberDetailsToken: memberData,
	};
	const url = `${PAISANOW_API_URL}BorrowerMemberFetchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getMemberContacts = (contactData) => {
	const data = {
		ContactToken: contactData,
	};
	const url = `${PAISANOW_API_URL}MemberContactFetch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getMemberBank = (bankData) => {
	const data = {
		BankDetailsToken: bankData,
	};
	const url = `${PAISANOW_API_URL}MemberBankDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getMemberLoan = (loanData) => {
	const data = {
		MemberLoanDetailsToken: loanData,
	};
	const url = `${PAISANOW_API_URL}MemberLoanFetch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// REJECT SECTION
export const memberReject = (encodedData) => {
	const data = {
		RejectMemberToken: encodedData,
	};
	const url = `${PAISANOW_API_URL}RejectMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};


// search member
export const searchMember = (payload) => {
    const data = {
        SearchMember: payload,
    };
    const url = `${PAISANOW_API_URL}SearchMemberLoanDetails`;
    return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
}