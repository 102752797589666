/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import JWT from 'expo-jwt';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { dashboardMenu, superAdminPages,managerMenu } from '../../../menu';
import Icon from '../../../components/icon/Icon';
import showNotification from '../../../components/extras/showNotification';
import {
	signInStart,
	signInReset,
	loginCompany,
	loginUser,
	loginUserType,
} from '../../../redux/login/login.actions';
import { selectCurrentUser, selectCurrentUserStatus } from '../../../redux/login/login.selector';

const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const Login = ({ isSignUp }) => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const [usernameInput, setUsernameInput] = useState(false);
	const [isNewUser, setIsNewUser] = useState(isSignUp);
	const getCurrentUserLoginDetails = useSelector(selectCurrentUser);
	console.log(getCurrentUserLoginDetails);
	const loginStatus = useSelector(selectCurrentUserStatus);
	const navigate = useNavigate();

	// console.log('getCurrentUserLoginDetails', getCurrentUserLoginDetails);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (loginStatus !== null) {
			if (loginStatus === 'Success' && getCurrentUserLoginDetails.data !== undefined) {
				if (getCurrentUserLoginDetails.data[0].statusCode === '01') {
					dispatch(loginCompany(getCurrentUserLoginDetails.data[0].UserCompanyID));
					dispatch(loginUser(getCurrentUserLoginDetails.data[0].UserID));
					dispatch(loginUserType(getCurrentUserLoginDetails.data[0].UserType));
					if (getCurrentUserLoginDetails.data[0].UserType === '1') {
						navigate(`${superAdminPages.Company.path}`);
					}else if(getCurrentUserLoginDetails.data[0].UserType === '6') {
						navigate(`${dashboardMenu.dashboard.path}`);
					}
					else {
						navigate(`${dashboardMenu.dashboard.path}`);
					}
				} else if (getCurrentUserLoginDetails.data[0].statusCode === '02') {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Cancel' size='lg' className='me-1' />
							<span>User Number Not Exist</span>
						</span>,
						'Wrong UserName or MobileNumber',
					);
				} else if (getCurrentUserLoginDetails.data[0].statusCode === '03') {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Cancel' size='lg' className='me-1' />
							<span>User Password Mismatch</span>
						</span>,
						'Wrong Password',
					);
				}
				dispatch(signInReset());
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginStatus]);
	const formik = useFormik({
		initialValues: {
			phoneNumber: '',
			password: '',
		},
		handleBlur: (from) => {
			if (from === 'ph') {
				if (phoneNumber.length < 10) {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Please Enter Correct UserNumber</span>
						</span>,
						'Valid UserNumber',
					);
				}
			}
		},
		onSubmit: (values) => {
			if (values.phoneNumber === '') {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='HourglassEmpty' size='lg' className='me-1' />
						<span>Please Enter the UserNumber</span>
					</span>,
					'Empty UserNumber',
				);
			} else if (values.phoneNumber.length < 10) {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Please Enter Correct UserNumber</span>
					</span>,
					'Valid UserNumber',
				);
			} else if (values.password === '') {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='HourglassEmpty' size='lg' className='me-1' />
						<span>Please Enter Password</span>
					</span>,
					'Empty Password',
				);
			} else {
				dispatch(signInStart({ values }));
			}
		},
	});

	return (
		<PageWrapper
			title={isNewUser ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-warning': !isNewUser, 'bg-info': !!isNewUser })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</Link>
								</div>

								<LoginHeader isNewUser={isNewUser} />

								<form className='row g-4' onSubmit={formik.handleSubmit}>
									<div className='col-12'>
										<FormGroup
											id='login-username'
											isFloating
											label='Mobilenumber'>
											<Input
												name='phoneNumber'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur('ph')}
												value={formik.values.phoneNumber}
												isTouched={formik.touched.Department}
												autoComplete='username'
												mask='9999999999'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup id='login-password' isFloating label='Password'>
											<Input
												type='password'
												value={formik.values.password}
												onChange={formik.handleChange}
												isTouched={formik.touched.Department}
												name='password'
												autoComplete='password'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button
											color='warning'
											type='submit'
											className='w-100 py-3'>
											Login
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
