// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import JWT from 'expo-jwt';
import ReactS3 from 'react-s3';
import { decodeTokenJson } from '../enode-decode';
import { PAISANOW_API_URL } from "../const";

// Decode
export const getEndodeJsonData = (jsonData, key) => {
	// console.log('jsonData', jsonData, 'key', key);
	const decodedData = JWT.decode(jsonData, key);
	return decodedData;
};

export const docodeResponse = (response, key) => {
	const docodeVal = decodeTokenJson(response.response, key);
	return docodeVal.data;
};

// S3 IMAGE UPLOAD
export const imageUploadToS3 = async (result, path) => {
	// Create a new Blob with the same content and the desired name
	const newFile = new Blob([result], { type: 'image/png' });
	newFile.name = `${Date.now()} -S3`.png;

	const config = {
		dirName: `${path}`,
		bucketName: 'paisanow-production',
		region: 'ap-south-1',
		accessKeyId: 'AKIAXLKVNHKWZ5FRI25C',
		secretAccessKey: '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',
		successActionStatus: 201,
	};

	// eslint-disable-next-line consistent-return
	const imageUrl = ReactS3.uploadFile(newFile, config).then((response) => {
		if (response.location) {
			return response.location;
		}
	});
	return imageUrl;
};

export const uploadImageMinIO = async (file, path, imageName) => {
	try {
		if (!file || !path || !imageName) {
			throw new Error('Missing required parameters');
		}

		const formData = new FormData();
		
		formData.append('photoimg', file); 
		formData.append('typeval', path);
		formData.append('imageNameVal', imageName);
		formData.append('fileFormat', 'Image');
		// eslint-disable-next-line no-debugger
		// debugger;
		console.log('Upload URL:', `${PAISANOW_API_URL}UploadImage.php`);
		console.log('Upload parameters:', { path, imageName, fileType: file.type });

		const response = await fetch(`${PAISANOW_API_URL}UploadImage.php`, {
			method: 'POST',
			body: formData
		});

		if (!response.ok) {
			console.error('Server returned an error:', response.status, response.statusText);
			throw new Error(`Upload failed: ${response.status} ${response.statusText}`);
		}

		const responseText = await response.json();
		console.log('Raw response:', responseText);

		if (responseText.status === 'Success') {
			return responseText.response;
		} 
		throw new Error('Upload failed: Invalid response status');


	} catch (error) {
		console.error('Error in uploadImageMinIO:', error);
		throw error;
	} 
};

export const fetchMonthList = () => {
	const monthArr = [
		{
			MonthID: 1,
			Month: 'January',
		},
		{
			MonthID: 2,
			Month: 'February',
		},
		{
			MonthID: 3,
			Month: 'March',
		},
		{
			MonthID: 4,
			Month: 'April',
		},
		{
			MonthID: 5,
			Month: 'May',
		},
		{
			MonthID: 6,
			Month: 'June',
		},
		{
			MonthID: 7,
			Month: 'July',
		},
		{
			MonthID: 8,
			Month: 'August',
		},
		{
			MonthID: 9,
			Month: 'September',
		},
		{
			MonthID: 10,
			Month: 'October',
		},
		{
			MonthID: 11,
			Month: 'November',
		},
		{
			MonthID: 12,
			Month: 'December',
		},
	];
	return monthArr;
};

export const fetchYearList = () => {
	const currentYear = new Date().getFullYear();
	const yearsArr = [];
	let startYear = 2020;
	// eslint-disable-next-line no-plusplus
	for (let i = startYear; i <= currentYear; i++) {
		const json = {
			YearID: yearsArr.length + 1,
			// eslint-disable-next-line no-plusplus
			Year: startYear++,
		};
		yearsArr.push(json);
	}
	return yearsArr;
};