import changeMeetingDateTypes from "./changeDate.types";

// change meeting date start
export const changeMeetingDateStart = (changeDate) => ({
    type: changeMeetingDateTypes.CHANGE_MEETING_DATE_START,
    payload: changeDate,
});

export const changeMeetingDateSuccess = (changeDate) => ({
    type: changeMeetingDateTypes.CHANGE_MEETING_DATE_SUCCESS,
    payload: changeDate,
});

export const changeMeetingDateFailure = (error) => ({
    type: changeMeetingDateTypes.CHANGE_MEETING_DATE_FAILURE,
    payload: error,
});

export const changeMeetingDateChange = () => ({
    type: changeMeetingDateTypes.CHANGE_MEETING_DATE_CHANGED,
});

export const changeMeetingDateReset = () => ({
    type: changeMeetingDateTypes.CHANGE_MEETING_DATE_RESET,
});
// change meeting date end